<template>
    <v-dialog max-width="500px" v-model="show">
        <Snackbar :snackbar="cpSnackbar.snackbarDlg" :snackbarColor="cpSnackbar.snackbarColor" :text="cpSnackbar.snackbarText" :title="cpSnackbar.snackbarTitle" :snackbarIcon="cpSnackbar.snackbarIcon" 
        @update-snackbar="updateSnackbar"></Snackbar>

        <v-card>
          <v-toolbar flat dark color="panel1">
            <v-toolbar-title>Upload File</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="show = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
            <div class="pa-5">
                <div v-if="currentFile">
                    <div>
                        <v-progress-linear
                            v-model="progress"
                            color="light-blue"
                            height="25"
                            reactive
                        >
                            <strong>{{ progress }} %</strong>
                        </v-progress-linear>
                </div>
                                </div>


        <v-row no-gutters>
            <div class="d-flex flex-no-wrap" v-if="file_type == 'image'">
                <v-avatar class="ma-3" size="125" tile>
                    <!--v-img :src="item.src"></v-img-->
                    <v-img :src="urlPreview"></v-img>
                </v-avatar>
            </div>        
      
      <v-col cols="12">
        <v-file-input v-if="file_type == 'image'"
          show-size
          label="File input"
          accept="image/*"
          @change="selectFile"
          :value="currentFile"
          v-model='image'
        ></v-file-input>
        <v-file-input v-if="file_type == 'excel'"
          show-size
          label="File input"
          accept=".xls,.xlsx"
          @change="selectFile"
          :value="currentFile"
          v-model='image'
        ></v-file-input>
      </v-col>
    </v-row>
  </div>
  <v-card-actions>
    <v-spacer></v-spacer>

            <v-btn color="cbtnSave" dark @click="upload">
          <v-icon left dark>mdi-cloud-upload</v-icon>
          Upload          
        </v-btn>
        <v-btn class="ml-2" color="cbtnRed" dark @click="show = false">
          <v-icon left dark>mdi-close</v-icon>
          Cancel
        </v-btn>

  </v-card-actions>
        </v-card>
</v-dialog>
</template>


<script>
import UploadService from "../services/UploadFilesService";
import Snackbar from '@/components/Snackbar'

export default {
    name: "upload-files",
    props: {
        file_type: String,
        route_type: String,
        visible:Boolean,
        caller:String,        
    },      
    components:{Snackbar},
    computed: {
      show: {
        get () {
          return this.visible
        },
        set (value) {
          if (!value) {
            this.$emit('close')
          }
        }
      }
    },    
  data() {
    return {
      currentFile: undefined,
      progress: 0,
      message: "",
      uploadParameter:[],
      //dialog: false,
        uploadResult:[],
        cpSnackbar:{
            snackbarDlg:false,
            snackbarTitle:'',
            snackbarText:'',
            snackbarColor:'',
            snackbarIcon:'',
        },      
      fileInfos: [],
      urlPreview: null,
      image:null,
    };
  },
  methods: {
    selectFile(file) {
      this.progress = 0;
      this.currentFile = file;
      //console.log(file);
      if(this.file_type == 'image'){
        //const file = file.target.files[0];
        this.urlPreview = URL.createObjectURL(this.image);
        console.log(this.urlPreview);
      }

    },

    upload() {
      if (!this.currentFile) {
            this.showErrorNotification("Please select a file!");
        return;
      }

      this.message = "";
      this.uploadParameter['file_type'] = this.file_type
      this.uploadParameter['route_type'] = this.route_type

      UploadService.upload(this.currentFile, (event) => {
        this.progress = Math.round((100 * event.loaded) / event.total);
      },this.uploadParameter)
        .then((response) => {
          //this.message = response.data.message;
          //return UploadService.getFiles();
          console.log(response);
          this.uploadResult = response.data;
          this.uploadResult['caller'] = this.caller;
          this.uploadResult['urlPreview'] = this.urlPreview;
          this.showSuccessNotification("File has been uploaded!");
          this.show = false;
          
          this.$emit('upload-done', this.uploadResult);
        })
        /*.then((files) => {
          this.fileInfos = files.data;
        })*/
        .catch(() => {
          this.progress = 0;
          this.showErrorNotification("Could not upload the file!");
          this.currentFile = undefined;
        });
    },
    //////////////////////////////////////////////
    //snackbar 
    updateSnackbar(e) {
      this.snackbar = e;
    },
    showErrorNotification(error_message){
        this.cpSnackbar.snackbarDlg = true,
        this.cpSnackbar.snackbarTitle = "Upload File"
        this.cpSnackbar.snackbarText  = error_message
        this.cpSnackbar.snackbarColor = 'error'
        this.cpSnackbar.snackbarIcon = 'mdi-alert-circle'

    },
    showSuccessNotification(error_message){
        this.cpSnackbar.snackbarDlg = true,
        this.cpSnackbar.snackbarTitle = "Upload File"
        this.cpSnackbar.snackbarText  = error_message
        this.cpSnackbar.snackbarColor = 'success'
        this.cpSnackbar.snackbarIcon = 'mdi-check-circle'

    },
    //////////////////////////////////////////////////

  }
};

</script>
