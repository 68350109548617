import axios from "axios"
let vlogin_id = localStorage.getItem('login_id');
class ApFileUploadService {


    list_fu_template() {
        vlogin_id = localStorage.getItem('login_id');
        let form = {login_id:vlogin_id};
        return axios.post("ap_file/list_fu_template", form);

    }
    
    view_fu_template(form) {
        vlogin_id = localStorage.getItem('login_id');
        form.login_id = vlogin_id;
        return axios.post("ap_file/view_fu_template", form);

    }

    insert_fu_template(form) {
        vlogin_id = localStorage.getItem('login_id');
        form.login_id = vlogin_id;
        return axios.post("ap_file/insert_fu_template", form);

    }

    update_fu_template(form) {
        vlogin_id = localStorage.getItem('login_id');
        form.login_id = vlogin_id;
        return axios.post("ap_file/update_fu_template", form);

    }    

    list_file_upload(form) {
        vlogin_id = localStorage.getItem('login_id');
        form.login_id = vlogin_id;
        return axios.post("ap_file/list_file_upload", form);

    }
    
    view_file_upload(form) {
        vlogin_id = localStorage.getItem('login_id');
        form.login_id = vlogin_id;
        return axios.post("ap_file/view_file_upload", form);

    }

    insert_file_upload(form) {
        vlogin_id = localStorage.getItem('login_id');
        form.login_id = vlogin_id;
        return axios.post("ap_file/insert_file_upload", form);

    }

    update_file_upload(form) {
        vlogin_id = localStorage.getItem('login_id');
        form.login_id = vlogin_id;
        return axios.post("ap_file/update_file_upload", form);

    }    

    load_file_upload(form) {
        vlogin_id = localStorage.getItem('login_id');
        form.login_id = vlogin_id;
        return axios.post("ap_file/load_file_upload", form);

    }

    validate_file_upload(form) {
        vlogin_id = localStorage.getItem('login_id');
        form.login_id = vlogin_id;
        return axios.post("ap_file/validate_file_upload", form);
    }

    process_file_upload(form) {
        vlogin_id = localStorage.getItem('login_id');
        form.login_id = vlogin_id;
        return axios.post("ap_file/process_file_upload", form);
    } 
    
    cancel_file_upload(form) {
        vlogin_id = localStorage.getItem('login_id');
        form.login_id = vlogin_id;
        return axios.post("ap_file/cancel_file_upload", form);
    }
    
    preview_file_upload(form) {
        vlogin_id = localStorage.getItem('login_id');
        form.login_id = vlogin_id;
        return axios.post("ap_file/preview_file_upload", form);
    }

    list_image_file(form) {
        vlogin_id = localStorage.getItem('login_id');
        form.login_id = vlogin_id;
        return axios.post("ap_file/list_image_file", form);
    }
}

    
 

export default new ApFileUploadService();