<template>
    <v-snackbar
            :value="snackbar"
            @input="closeSnackbar"
            top
            :timeout="1500" 
            auto-height
            :color= "snackbarColor"
            :multi-line="true" 
    >
        
     <v-layout align-center pr-4>
        <v-icon class="pr-3" dark large>{{ snackbarIcon }}</v-icon>
        <v-layout column>
          <div>
            <strong>{{ title }}</strong>
          </div>
          <div>{{ text }}</div>
        </v-layout>
        <v-spacer></v-spacer>
                <v-btn icon @click="closeSnackbar(false)">
            <v-icon >mdi-close-circle</v-icon>
        </v-btn>

      </v-layout>

    </v-snackbar>

     
</template>

<script>
    export default {
        name: "Snackbar",
        props: {
            snackbar: Boolean,
            text: String,
            title: String,
            snackbarColor: String,
            snackbarIcon: String,
        },
        methods: {
            closeSnackbar(snackbar) {
                this.$emit('update-snackbar', snackbar);
            }
        },
    }
</script>