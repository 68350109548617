<template>
        <v-dialog max-width="500px" v-model="show">
                <DlgMessage ref="dlgMsg" @dlgClose="dlgClose"></DlgMessage>
                <v-card>
                        <v-toolbar flat dark color="panel1">
                                <v-toolbar-title>Image File List</v-toolbar-title>
                                <v-spacer></v-spacer>
                                <v-btn icon dark @click="show=false">
                                        <v-icon>mdi-close</v-icon>
                                </v-btn>
                        </v-toolbar>
                        <div class="pa-5">
                                <v-container>
                                    <h2 class="text-center">Gallery</h2>
                                    <v-row>
                                        <v-col v-for="(file,index) in imageFileList.fileNameList" :key="'indexFile' + index" class="d-flex child-flex" cols="6" sm="4">
                                        <v-card flat tile class="d-flex">
                                            <v-img :src="imageFileList.pathUrl + file" aspect-ratio="1" class="grey lighten-2" @click="selectFile(index)" @dblclick="selectImage(true)">
                                            <template v-slot:placeholder>
                                                <v-row class="fill-height ma-0" align="center" justify="center">
                                                <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                                                </v-row>
                                            </template>
                                            </v-img>
                                        </v-card>
                                        </v-col>
                                    </v-row>
                            </v-container>
                        </div>
                        <v-card-actions>
                                <v-img v-if="selectedImage.fileUrl" :src="selectedImage.fileUrl + selectedImage.imageFileName" aspect-ratio="1"/>
                                <v-spacer></v-spacer>
                                <v-btn color="cbtnSave" dark @click="selectImage(true)">
                                        <v-icon left dark>mdi-check</v-icon>
                                        Select
                                </v-btn>
                                <v-btn class="ml-2" color="cbtnRed" dark @click="selectImage(false)">
                                        <v-icon left dark>mdi-close</v-icon>
                                        Close
                                </v-btn>
                        </v-card-actions>
                </v-card>
        </v-dialog>
</template>


<script>
import DlgMessage from '@/components/DlgMessage'
//import axios from 'axios';
import ApFileUploadService from "@/services/ApFileUploadService"

export default {
        name: "DlgImageMedia",
        props: {
                visible:Boolean,
                route_type:String,
                section:String,
        },      
        components:{DlgMessage},
        computed: {
            show: {
                get () {
                    return this.visible
                },
                set (value) {
                     if (!value) {
                        this.$emit('close',this.selectedImage)
                     }
                }
            },
            
        },      
        data() {
        return {
                
            message: "",
            customer_id:0,
            form:{
                    login_id:"",
                    route_type:"",
                },
            selectedImage:{
                imageFileName:"",
                fileUrl:"",
                section:""
            },
            imageFileList:{
                fileNameList:[],
                pathUrl:""
            },
        };
    },
    async created(){
        this.listImageFile();
    },
    methods: {
        dlgShow(){
        },
        dlgClose(){
                console.log(this.respCode);
                
                if(this.respCode == 200){
                        this.form.c_code = "";
                        this.form.c_name = "";
                        this.form.sequence = 0;
                        this.show = false;
                }
            
       
        },

        async listImageFile(){
            let title = "Image File List";
            this.form.route_type = this.route_type;
            this.selectedImage.section = this.section;
            console.log("route _type",this.route_type);
            try{
                    ApFileUploadService.list_image_file(this.form).then((res) => {
                        this.imageFileList = res.data;
                        console.log("image File",this.imageFileList);
                    }).catch((e)=>{
                        
                        if(e)
                        this.$refs.dlgMsg.showDlgBox(true,'error',title,e.response.data.error_message);
                    })
                    ;
            } catch(e) {
                    this.loadingFlag = false
                    this.$refs.dlgMsg.showDlgBox(true,'error',title,'Unknown Error');
                    
            }             

        },
        selectImage(flag){
            if(!flag){
                this.selectedImage.imageFileName = "";
                this.selectedImage.fileUrl = "";                
                this.selectedImage.section = this.section;
            }
            this.show = false;
        },

        selectFile(index){
            this.selectedImage.imageFileName = this.imageFileList.fileNameList[index];
            this.selectedImage.fileUrl = this.imageFileList.pathUrl;
            this.selectedImage.section = this.section;
            // console.log(this.selectedImage)
        }



    }
};

</script>
